<template>
  <div class="corporateCulture">
    <div class="main">
      <div class="content">
        <div
          v-for="(item, index) of list"
          :key="item.id"
          :class="index % 2 == 0 ? 'content_top' : 'content_bottom'"
        >
          <div class="img">
            <img class="img" :src="item.pagePicture" alt="" />
          </div>
          <div class="content_text" v-html="item.content"></div>
        </div>
      </div>
      <div class="idea" v-html="idea"></div>
    </div>
  </div>
</template>
 
<script>
import { queryCultureOfFirmList } from "@/api/api.js";
export default {
  name: "corporateCulture",
  data() {
    return {
      list: [],
      idea: "",
    };
  },
  created() {
    queryCultureOfFirmList().then((res) => {
      this.list = res.data.rows.splice(1);
      this.idea = res.data.rows[0].content;
    });
  },
  methods: {},
};
</script>
 
<style scoped lang="less">
* {
  list-style: none;
  margin: 0;
  padding: 0;
}
.corporateCulture {
  width: 100%;
  .main {
    max-width: 900px;
    margin: 30px auto;
    .content {
      margin: 50px 0;
      padding-bottom: 50px;
      border-bottom: 1px dashed #707070;
      > div {
        display: flex;
        position: relative;
        .content_text {
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.16);
          background: #fff;
        }
      }
      .content_top {
        .content_text {
          right: 0;
        }
      }
      .content_bottom {
        margin-top: 50px;
        text-align: right;
        justify-content: end;
        .content_text {
          left: 0;
        }
      }
    }
    .idea {
    }
  }
}
@media screen and (min-width: 780px) {
  .corporateCulture {
    .main {
      .content {
        > div {
          padding-bottom: 60px;
          .img {
            width: 450px;
            height: 300px;
          }
          .content_text {
            padding: 60px 90px;
            width: 500px;
            height: 300px;
            position: absolute;
            top: 50px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .corporateCulture {
    .main {
      width: 90%;
      .content {
        > div {
          flex-wrap: wrap;
          .img {
            img {
              width: 100%;
            }
          }
          .content_text {
            margin-top: 20px;
            padding: 30px 50px;
            order: 1;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
